import React from "react";
import Swal from 'sweetalert2';
import { useEffect } from "react";
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { View, Button } from '@aws-amplify/ui-react';


function FaceLiveness({faceLivenessAnalysis,bearer,callback}) {
    const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)

    const fetchCreateLiveness = async () => {
        const response = await fetch('https://cires.smoothconcepts.ng/api/v1/initiate-liveness',{
            headers: {
                'Authorization': 'Bearer ' + bearer,
            },
        });
        const data = await response.json();
        console.log(data.data.session_id);
        setSessionId(data.data.session_id)
        setLoading(false);

    };
    useEffect(() => {
        fetchCreateLiveness();
    },[])

    const handleTryAgain = () => {
        setLoading(true);
        setSessionId(null);
        fetchCreateLiveness();
      };
    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async () => {
        /*
         * API call to get the Face Liveness Session result
         */
        const response = await fetch('https://cires.smoothconcepts.ng/api/v1/liveness-results',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + bearer,
                },
                body: JSON.stringify({ sessionid: sessionId })
            }

        );
        const data = await response.json();
        console.log(data)
        var backto = callback ? callback : ""
        if(data.message){
            Swal.fire({
                title: data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                }).then((result) => {
                    if(result.isConfirmed){
                        window.location.href = "https://lionfish-app-qi9og.ondigitalocean.app/"+backto
                    }
                })
        }else{
            Swal.fire({
                title: "Registration Successful",
                html: "Your Unique ID is <b>" + data.data.individual_id + "</b>",
                icon: 'success',
                confirmButtonColor: '#198754',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "https://lionfish-app-qi9og.ondigitalocean.app/"+backto
                }
              })
        }

    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="us-west-2"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={(error) => {
                        console.error(error);
                        // handleTryAgain()
                      }}
                      components={{
                        ErrorView: ({ children }) => {
                            return (
                              <View
                                as="div"
                                backgroundColor="var(--amplify-colors-white)"
                                borderRadius="6px"
                                border="1px solid var(--amplify-colors-black)"
                                maxWidth="100%"
                                padding="1rem"
                                width="20rem"
                                textAlign="center"
                                >
                                {children}
                                <br/>
                                <Button onClick={handleTryAgain}
                                variation="primary"
                                >Try Again</Button>
                              </View>
                            );
                          }
                      }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
