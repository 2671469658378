import './style.css';
import React from "react";
import Biometric from './Biometric';
function App() {
  const searchParams = new URLSearchParams(document.location.search)
  const token = searchParams.get('token')
  const callbackURL = searchParams.get('callbackURL') ? searchParams.get('callbackURL') :false
  console.log(token)
  return token ? <Biometric bearer={token} callback={callbackURL}/> : "Cires Verification"
}

export default App;
